import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { BsArchive, BsPencil, BsThreeDotsVertical, BsTrash, BsPlusSquare } from "react-icons/bs";
import { GoChevronDown } from "react-icons/go";
import Table from "./Table";
import { MITARBEITER_DETAILS, MITARBEITER_ZUORDNUNG, UNTERSUCHUNGEN } from "../../utils/TableConstanten";

const TableBody = ({ data, columns, onDelete, onArchive, onEdit, rowClicks, onNachuntersuchung, embedded }) => {
  const [expandedRowId, setExpandedRowId] = useState(null);

  const formatValue = (value, format) => {
    if (format && value) {
      return moment(value).format(format);
    }
    return value;
  };

  const getNestedValue = (obj, path) => {
    const parts = path.split('.');
    let current = obj;

    for (let part of parts) {
      if (Array.isArray(current)) {
        current = current.map(item => item[part]).filter(Boolean).join(', ');
      } else {
        current = current[part];
      }
      if (!current) break;
    }
    return current;
  };

  const stackGroup = (column, row) => {
    if (column.group) {
      return column.group.map(field => {
        if (row[field] === 0) {
          return "";
        }
        return (
          <div key={field} className="statistic">
            <span className={`statisticCircle ${field}`}></span>
            {row[field]}
          </div>
        );
      });
    }
    return "";
  };

  const handleRowClick = (e, rowId) => {
    setExpandedRowId(prevId => (prevId === rowId ? null : rowId));
  };

  return (
    <tbody className="table-group-divider">
      {data.map((row, rowIndex) => {
        return <React.Fragment key={rowIndex}>
          <tr>
            {columns.map((column) => (
              <td key={column.dbName}>
                {column.dbName === "statistik" && column.group
                  ? stackGroup(column, row)
                  : formatValue(getNestedValue(row, column.dbName), column.format)}
              </td>
            ))}
            <td style={{ textAlign: "right" }}>
              { rowClicks ? 
              (<button 
                onClick={(e) => handleRowClick(e, row.id)}
                className="btn btn-secondary tableActionButton"
                ><GoChevronDown className={expandedRowId === row.id ? 'rotated' : ''} />
               </button>) 
              :
              (<UncontrolledDropdown className="tableAction">
                <DropdownToggle color="secondary" className="tableActionButton">
                  <BsThreeDotsVertical />
                </DropdownToggle>
                <DropdownMenu>
                  {embedded && onNachuntersuchung ? (<>
                    <DropdownItem disabled={row.id === null} onClick={onEdit.bind(null, row.id, "untersuchungen")}>
                      <BsPencil /> <span>Untersuchung bearbeiten</span>
                    </DropdownItem>
                    <DropdownItem onClick={() => onNachuntersuchung(row, "untersuchungen")}>
                      <BsPlusSquare /> <span>Nachuntersuchung erfassen</span>
                    </DropdownItem>
                  </>):( <>
                    <DropdownItem onClick={() => onEdit(row.id)}>
                      <BsPencil /> <span>Bearbeiten</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className="deleteItem" onClick={() => onDelete(row.id)}>
                      <BsTrash /> Löschen
                    </DropdownItem>
                  </>)}
                  {onArchive && (
                    <DropdownItem onClick={() => onArchive(row.id)}>
                      <BsArchive /> Archivieren
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>)
              }
            </td>
          </tr>
          {!embedded && expandedRowId === row.id && (
            <tr className="expanded-row">
              <td colSpan={columns.length + 1}>
                <Table
                  apiEndpoint={MITARBEITER_DETAILS+"/"+row.id}
                  embedded={true}
                  onClick={false}
                  onRowEdit={onEdit}
                  onNachuntersuchung={onNachuntersuchung}
                />
              </td>
            </tr>
          )}
        </React.Fragment>
      })}
    </tbody>
  );
};

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dbName: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.any,
  onEdit: PropTypes.any,
  onArchive: PropTypes.any,
  rowClicks: PropTypes.any,
  embedded: PropTypes.any,
  onNachuntersuchung: PropTypes.any
};

export default TableBody;
