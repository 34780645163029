import React, { Component } from 'react';
import TableBox from './Table/TableBox';

export default class ReportMitarbeiterBox extends Component {
    constructor(props) {
        super(props);
        this.reportSchema = {
            type: 'object',
            properties: {}
        }
    }

    validate(props) { 
    }

    componentDidMount() {
        this.validate(this.props);
        // this.props.changeTitle("Vorsorge");
    }

    componentDidUpdate(newProps){
        if(newProps !== this.props){
          this.validate(newProps)
        }
    }

    componentWillUnmount() {
        this.props.changeTitle(null);
    }


    render() {
        let content = <div className="d-none">Leer</div>;
            content =
                <TableBox
                    title="Mitarbeiter Übersicht"
                    resourceName={"report/mitarbeiter_uebersicht"}
                    schema={this.reportSchema}
                    setCsvQuery={this.props.setCsvQuery}
                    rowClicks={true}
                    report={true} />;
        return (content);
    }
}



